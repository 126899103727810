import React, { useContext } from "react";
import styled from "styled-components";
import BannerSection from "../BannerSection";
import Header from "../Header";
import Footer from "./Footer";
import { KBContext } from "../../Context";
import { CategoryLoader } from "../Loaders/CategoryLoader";
import AdminFooter from "../AdminFooter";
import { isFeatureEnabled } from "../../utils";
import { poweredByHiver } from "../../Constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
`;

export default function Layout(props) {
  const { data, showDashboardFooter, availableFeatures } = useContext(KBContext);
  const showPoweredByHiverFooter = isFeatureEnabled(poweredByHiver, availableFeatures);

  if(data){
  return (
    <Wrapper tabindex="1" id="mainWrapper">
      <Header bannerData={data} />
      <BannerSection bannerData={data} />
      <div style={{marginBottom: '40px'}}>
        {props.children}
      </div>
      {/* <Outlet /> */}
      {showPoweredByHiverFooter && <Footer />}
      {showDashboardFooter ? <AdminFooter/> : ''}
    </Wrapper>
  );
  }else{
    return <CategoryLoader />
  }
}
